<template>
  <StandByHeader />
  <Main />
</template>

<script>
import StandByHeader from "./components/sections/StandByHeader.vue";
import Main from "./components/sections/Main.vue";
export default {
  data: function () {
    return {
      mode: process.env.NODE_ENV.toString,
    };
  },
  components: {
    StandByHeader,
    Main,
    // Form,
  },
};
</script>

