
import './assets/scss/app.scss';

import { createApp } from 'vue'
import { createWebHistory, createRouter } from "vue-router";

import App from './App.vue'

import 'core-js/stable'

import { initHeader } from './plugins/elements/header.js'
import { initFooter } from './plugins/elements/footer.js'
import { initScroll } from './plugins/base/scroll.js'

import { initLazyLoad } from './plugins/base/lazy-load.js'

import { initGradientMovement } from './plugins/elements/gradient-movement.js'
import { initServiceSlider, initRelatedSlider } from './plugins/elements/sliders.js'
import { initVideoControls } from './plugins/elements/video-controls.js'
import { initCounter } from './plugins/elements/counter.js'
import { initFixedCTA } from './plugins/elements/fixed-cta.js'

const initApp = () => {
  initScroll()
  initLazyLoad()

  initHeader()
  initFooter()
  initGradientMovement()

  initServiceSlider()
  initRelatedSlider()
  initVideoControls()
  initCounter()
  initFixedCTA()
}

document.addEventListener('DOMContentLoaded', initApp)

// Replace the standard import with a function returning the 
// results from the import function 
import Contact from './Contact.vue'
import Thankyou from './Thankyou.vue'

const routes = [
  {
    path: "/",
    name: "Contact",
    component: Contact
  },
  {
    path: "/thank-you",
    name: "Thankyou",
    component: Thankyou
  },
];

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
  history: createWebHistory(),
  routes,
});

const app = createApp(App).use(router)

app.mount('#app')
