<template>
<div>
</div>

</template>

<script>
export default {
  data: function () {
    return {
      mode: process.env.NODE_ENV,
    };
  },
};
</script>
